import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import AuthorPage from 'core/components/AuthorPage';

import ListPageLayout from 'site/components/ListPageLayout';
import CardHorizontalDesktop, { CardHorizontalMobile } from 'site/cards/CardHorizontal';

const limit = 10;


function Author({ author, topics, isDesktop }) {
  return (
    <ListPageLayout showRecommender contextCount={1} >
      <AuthorPage
        author={author}
        topics={topics}
        card={isDesktop ? CardHorizontalDesktop : CardHorizontalMobile}
        limit={limit}
      />
    </ListPageLayout>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    card: CardHorizontalDesktop,
    limit,
    include: 'image,rubric',
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
