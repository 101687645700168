export default {
  0: {
    avatarWidth: 60,
    mobile: {
      avatarWidth: 60,
    },
  },
  1: {
    noAvatar: true,
    mobile: {
      noAvatar: true,
    },
  },
  2: {
    avatarWidth: 80,
    jobTitleAsMeta: true,
    bottomLine: true,
  },
  3: {
    avatarWidth: 300,
    asLink: true,
    jobTitleAsMeta: true,
    fatName: true,
    overlayed: true,
    aspectRatio: 1.07,
    nameFontSize: true,
    jobTitleFontSize: true,
    noMargin: true,
    mobile: {
      avatarWidth: 90,
      asLink: true,
      jobTitleAsMeta: true,
      topLine: true,
      fatName: true,
      topName: true,
      nameFontSize: true,
      jobTitleFontSize: true,
    },
  },
  4: {
    avatarWidth: 80,
    jobTitleAsMeta: true,
    fatName: true,
    opinionFont: true,
    mobile: {
      avatarWidth: 40,
      jobTitleAsMeta: true,
      fatName: true,
      noMargin: true,
    },
  },
};
