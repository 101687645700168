import PropTypes from 'prop-types';

import StickyPortal from 'core/components/StickyPortal';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';
import SelfPromo from 'core/components/SelfPromo';

import { Indent } from 'core/components/Wrappers';
import { PartnersVertical } from 'site/components/Partners';

import {
  Ad240x400,
  Ad240x400Second,
} from 'site/components/Ads/desktop';

import { SMALL_INDENT } from 'site/constants';


export default function InnerSideColumn({ showBoesque }) {
  return (
    <>
      {showBoesque && (
        <Indent bottom={SMALL_INDENT}>
          <Bouesque iconSize={24} />
        </Indent>
      )}
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper bottom={SMALL_INDENT}>
          <Ad240x400 />
          <SelfPromo />
          <Indent bottom={SMALL_INDENT} />
          <PartnersVertical />
          <Indent bottom={SMALL_INDENT} />
        </AdWrapper>
      </StickyPortal>
      <StickyPortal maxOffset={2400}>
        <Ad240x400Second />
      </StickyPortal>
    </>
  );
}

InnerSideColumn.defaultProps = {
  showBoesque: true,
};

InnerSideColumn.propTypes = {
  showBoesque: PropTypes.bool,
  infinityIndex: PropTypes.number,
};
