import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import Link from 'core/components/Link';
import cardHOC from 'core/components/card';
import { Mobile, Desktop } from 'core/components/breakpoint';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import SiteImage from 'site/components/SiteImage';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card1(props) {
  const {
    content,
    theme: {
      animations: {
        hover: hoverAnimation,
      },
      texts: {
        display1: display1Atoms,
        caption4: caption4Atoms,
      },
      colors: colorsAtoms,
    },
    isMobile,
    highPriorityImage,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: alternativeHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const Icon = require('site/icons/arrowRight').default;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          .link:hover &
            color ${colorsAtoms.active1}
      `}</style>
    </scope>
  );

  const imageProps = {
    src: cover,
    previewSrc: previewCover,
    aspectRatio: 1.6,
    ...highPriorityImage && { fetchPriority: 'high', instant: true },
  };

  return (
    <div className={cx(styles.card1, 'card1')} data-qa='main-topic'>
      <style jsx>{`
        .headline
          font ${display1Atoms.font}
          color ${display1Atoms.color}
          transition color ${hoverAnimation}

        .altHeadline
          font ${caption4Atoms.font}
          color ${caption4Atoms.color}

        .overlay
          transition opacity ${hoverAnimation}

        .image
          position ${isMobile ? 'relative' : 'absolute'}

        .innerContent
          width ${isMobile ? 'auto' : '495px'}
          border 4px solid ${colorsAtoms.active1}
          background ${color(colorsAtoms.layout).alpha(0.4).string()}
          :global([id*="$"])
            fill ${colorsAtoms.active1}
      `}
      </style>

      <Link
        className={cx(styles.link, scope.wrapClassNames('link'))}
        to={link}
        type={'secondary'}
      >
        <div className={cx(!isMobile && styles.content)}>
          <div className={cx('image', styles.image)}>
            <Desktop>
              <SiteImage
                width={495}
                {...imageProps}
              />
            </Desktop>
            <Mobile>
              <SiteImage
                maxWidth={970}
                {...imageProps}
              />
            </Mobile>
            <div className={cx(styles.overlay, 'overlay')} />
          </div>
          <div className={cx('innerContent', styles.innerContent)}>
            <div className={cx(styles.headline, 'headline', scope.wrapClassNames('headline'))} data-qa='card-headline'>{listHeadline || headline}</div>
            <div className={cx(styles.altHeadline, 'altHeadline')}>{alternativeHeadline}</div>
            <Icon className={styles.icon} />
          </div>
        </div>
      </Link>
      <scope.styles />
    </div>
  );
}

Card1.propTypes = {
  /** Тип карточки */
  type: PropTypes.number,
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /**
  * Использование fetchPriority для загрузки картинки
  */
  highPriorityImage: PropTypes.bool,
};

const Card = cardHOC(Card1);

Card.displayName = 'Card1';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card1 as StorybookComponent };
