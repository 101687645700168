import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import bindPropsHOC from 'core/components/bindProps';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Recommender from 'core/components/Recommender';
import Drum from 'core/components/Drum';

import { SMALL_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';
import { Indent, NegativeMobile } from 'core/components/Wrappers';
import Card3 from 'site/cards/Card3';
import Header from 'site/components/Header';

function SiteRecommender({ isMobile }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .container
          padding 0 ${isMobile ? SIDE_INDENT_MOBILE + 'px' : '' }
      `}</style>
    </scope>
  );


  return (
    <>
      <NegativeMobile>
        <Recommender
          blockId={isMobile ? '2b10ed3728e349a2aebf698e5e161365' : '058b3266509b4b9faf3191889f746a34'}
          renderer={Drum}
          title='Самое интересное'
          containerClass={scope.wrapClassNames('container')}
          headerComponent={({ children }) => (
            <div style={isMobile ? { marginLeft: SIDE_INDENT_MOBILE } : {}}>
              <Header type='h2'>{children}</Header>
              {!isMobile && <Indent top={SMALL_INDENT} />}
            </div>
          )}
          interitemSpacing={20}
          card={bindPropsHOC({ type: 3 })(Card3)}
          columns={3}
          itemWidthMobile={280}
          grid
          limit={6}
        />
      </NegativeMobile>
      <scope.styles />
    </>
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
