import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Feed from 'core/components/Feed';
import CompareCard from 'site/cards/CompareCard';
import { Indent, NegativeMobile } from 'core/components/Wrappers';

const Card = (props) => (
  <NegativeMobile>
    <CompareCard {...props} />
  </NegativeMobile>
);


function GameCompareRubric({ topics, isDesktop }) {
  return (
    <Fragment>
      {topics.length > 0 && (
        <Indent top={20}>
          <Feed
            content={topics}
            card={Card}
            interitemSpacing={isDesktop ? 30 : 20}
          />
        </Indent>
      )}
    </Fragment>
  );
}

GameCompareRubric.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(GameCompareRubric);
