import color from 'core/libs/color';

import palette from './palette';

const colors = {
  layout: palette.white,
  content: palette.white,
  active1: '#ffb800',
  active2: '#ffb800',
  active3: '#ffc93e',
  accent: '#ff3e56',
  error: '#ff3e56',
  primary: palette.black,
  lightblue1: '#e0e5ff',
  lightblue2: '#abb6e4',
  get primary400() { return color(this.primary).alpha(0.4).string(); },
  input: palette.white,
  spammerBackground: '#f8f8f8',
  grey: '#b3b3b3',
  grey2: '#f2f2f2',
  grey3: '#dbdbdb',
  gray100: palette.gray100,
  gray300: palette.gray300,
  gray600: palette.gray600,
  gray700: palette.gray700,
  get divider() {
    return color(this.primary).alpha(0.1).string();
  },
  get quote() {
    return color(this.primary).alpha(0.05).string();
  },
  get hint() {
    return color(this.primary).alpha(0.3).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder2() {
    return color(this.primary).alpha(0.1).string();
  },
  get theme() {
    return this.active1;
  },
};

export default {
  colors,
};

