// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 15;

export const MEDIUM_SIDE_INDENT = 30;

export const VERTICAL_INDENT = 40;
export const VERTICAL_INDENT_MOBILE = 15;

export const SMALL_INDENT = 20;

// limits
export const RUBRICS_PAGE_LIMIT = 25;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 860;
export const RIGHT_COLUMN_WIDTH = 300;

// TODO: вытащить в core/utils/prop-types/themeContracts.js
export const textContract = '{ font, color }';

export const newsBaseUrl = '/news';
