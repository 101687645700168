import cx from 'classnames';

import ListPageLayoutSkeleton from 'site/components/ListPageLayout/Skeleton';

import styles from './index.styl';


function MainPageSkeleton() {
  return (
    <ListPageLayoutSkeleton>
      <div className={styles.wrapper}>
        <div className={cx(styles.mainBlock, 'lb-skeleton')} />
        <div className={styles.newsBlock}>
          <div className={cx(styles.news, 'lb-skeleton')} />
          <div className={styles.topics}>
            <div className={cx(styles.topic, 'lb-skeleton')} />
            <div className={cx(styles.topic, 'lb-skeleton')} />
            <div className={cx(styles.topic, 'lb-skeleton')} />
          </div>
        </div>
      </div>
    </ListPageLayoutSkeleton>
  );
}

export default MainPageSkeleton;
