import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import CoreSearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
} from 'site/components/Ads/mobile';
import Boroda from 'site/components/Boroda';

import CardHorizontalDesktop, { CardHorizontalMobile } from 'site/cards/CardHorizontal';


function SearchPage({ rawContent, isDesktop }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
        <CoreSearchPage
          rawContent={rawContent}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={Listing2}
          card={isDesktop ? CardHorizontalDesktop : CardHorizontalMobile}
          iconSize={24}
        />
        <Boroda showRecommender />
      </ColumnLayout>
    </PageIndent>
  );
}

SearchPage.propTypes = {
  rawContent: PropTypes.object,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: CardHorizontalDesktop }),
});

export default withPageHocs(dataProvider)(withBreakpoint(SearchPage));
