import color from 'core/libs/color';

import Logo from 'site/components/Logo';

import palette from './palette';

const colors = {
  layout: palette.black,
  content: palette.black,
  active1: '#fdb712',
  active2: '#fdb712',
  accent: '#ff3e56',
  error: '#ff3e56',
  primary: palette.white,
  input: palette.black,
  get divider() { return color(this.primary).alpha(0.1).string(); },
  get placeholder() { return color(this.primary).alpha(0.5).string(); },
  get hint() { return color(this.primary).alpha(0.5).string(); },
  get primary400() { return color(this.primary).alpha(0.4).string(); },
  get theme() {
    return this.active1;
  },
};

const button = {
  secondaryGrey: {
    idle: {
      color: '#666666',
      background: 'none',
      border: '1px solid #666666',
    },

    hover: {
      color: colors.layout,
      background: 'none',
      border: `1px solid ${colors.layout}`,
    },
  },
};

const shapka = {
  logo: {
    Icon: Logo,
  },
};

const login = {
  popup: {
    content: {
      color: colors.primary,
    },
  },
};

export default {
  colors,
  icons: {
    logo: Logo,
  },
  controls: {
    button,
    shapka,
    login,
  },
};

