import readMore from 'core/components/TopicContent/blocks/readMore';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import Rug from 'site/components/Rug';

import CardReadMore1 from 'site/cards/CardReadMore1';
import CardReadMore2 from 'site/cards/CardReadMore2';

export default function ReadMore({ block }) {
  const contentArr = block && block.attributes ? block.attributes.topic_ids : [];

  if (!contentArr.length) {
    return null;
  }

  return (
    <div className='readMore' data-qa='read-more'>
      <Rug>
        {readMore(
          block,
          {
            title: 'Читайте также',
            singleCard: CardReadMore1,
            feedCard: CardReadMore1,
            drumCard: CardReadMore2,
          }
        )}
      </Rug>
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
};
