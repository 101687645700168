import { Fragment } from 'react';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Mobile, Desktop } from 'core/components/breakpoint';

import {
  Sponsored as DesktopSponsored,
} from 'site/components/Ads/desktop';

import {
  Sponsored as MobileSponsored,
} from 'site/components/Ads/mobile';

import {
  SMALL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

export default function Sponsored() {
  return (
    <Fragment>
      <Mobile>
        <AdWrapper bottom={VERTICAL_INDENT_MOBILE}>
          <MobileSponsored />
        </AdWrapper>
      </Mobile>
      <Desktop>
        <AdWrapper bottom={SMALL_INDENT}>
          <DesktopSponsored />
        </AdWrapper>
      </Desktop>
    </Fragment>
  );
}

