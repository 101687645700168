import PropTypes from 'prop-types';

import SmartImage from 'core/components/SmartImage';
import { imageVersionAttributes } from 'core/utils/prop-types/model';

import Logo from 'site/icons/LogoSymbolOnly';

import styles from './index.styl';

/**
 * TODO:
 * Интегрировать в `core/components/SmartImage` с возможностью
 * передавать пропсом подложку как компонент?
 */

function SiteImage(props) {
  if (props.src || props.url) return <SmartImage {...props} />;

  const {
    aspectRatio,
    width,
    height,
    maxWidth,
    maxHeight,
  } = props;

  const defaultRatio = 16 / 9;

  return (
    <div
      className={styles.siteImage}
      style={{
        width,
        height,
        maxWidth,
        maxHeight,
      }}
    >
      <div className={styles.logoWrapper}>
        <Logo width='50%' height='50%' />
      </div>
      <div style={{ paddingTop: `${100 / (aspectRatio || width / height || defaultRatio)}%` }} />
    </div>
  );
}

SiteImage.propTypes = {
  /**
   * Атрибут, из которого компонент берет URL для изображения,
   * которое будет выведено на экран. Атрибут берется из API.
   */
  src: imageVersionAttributes,
  /**
   * URL внешнего изображения.
   */
  url: PropTypes.string,
  /**
   * Соотношение сторон картинки
   */
  aspectRatio: PropTypes.number,
  /**
   * Ширина картинки
   */
  width: PropTypes.number,
  /**
   * Высота картинки
   */
  height: PropTypes.number,
  /**
   * Максимальная ширина картинки
   */
  maxWidth: PropTypes.number,
  /**
   * Максимальная высота картинки
   */
  maxHeight: PropTypes.number,
};

export default SiteImage;
