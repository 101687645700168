import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Grid from 'core/components/Grid';
import GameCompare from 'core/components/GameCompare';
import Topic from 'core/components/Topic';
import InfiniteTopic from 'core/components/InfiniteTopic';
import Erid from 'core/components/Erid';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import resolveRelationships from 'core/utils/relationships';
import { resolve } from 'core/utils/env';
import { isGameCompare } from 'core/components/GameCompare/utils';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';
import EmptyWrapper from 'core/components/EmptyWrapper';

import Mark from 'site/components/Mark';
import ProsAndCons from 'site/components/ProsAndCons';
import InnerSideColumn from 'site/components/InnerSideColumn';

import TopicContent from './Content';
import TopicHeader from './Header';
import TopicFooter from './Footer';
import TopicHeaderPremium from './HeaderPremium';

import {
  VERTICAL_INDENT_MOBILE,
  SMALL_INDENT,
  CONTENT_AREA,
} from 'site/constants';

import { TopBanner } from 'site/components/Ads/mobile';

const relationships = resolveRelationships(['review']);


const extendedTopicRenderer = (topic, enhancedTopic, isGameComparePage, isDesktop) => {
  const {
    attributes: {
      is_premium: isPremium,
      erid,
    },
  } = topic;

  const {
    infinityIndex,
  } = enhancedTopic;

  const {
    review,
  } = relationships(topic);

  const verticalIndent = isDesktop ? SMALL_INDENT : VERTICAL_INDENT_MOBILE;
  const Wrapper = isPremium ? EmptyWrapper : ColumnLayout;

  return (
    <div data-qa='topic'>
      {infinityIndex > 0 && !isDesktop && (
        <Indent top={VERTICAL_INDENT_MOBILE}>
          <TopBanner lazy />
        </Indent>
      )}
      <PageIndent>
        {isPremium && (
          <Indent bottom={verticalIndent}>
            <TopicHeaderPremium content={topic} infinityIndex={infinityIndex} />
          </Indent>
        )}
        <Wrapper rightColumn={<InnerSideColumn />}>
          <Grid maxWidth={CONTENT_AREA}>
            {!isPremium && (
              <Indent top={isDesktop ? 0 : 24} bottom={20}>
                <TopicHeader content={topic} isGameCompare={isGameComparePage} />
              </Indent>
            )}
            {!isGameComparePage &&
              <>
                <TopicContent />
                <Indent
                  top={isDesktop ? SMALL_INDENT : VERTICAL_INDENT_MOBILE}
                  bottom={isDesktop ? SMALL_INDENT : VERTICAL_INDENT_MOBILE}
                >
                  <Mark review={review} />
                </Indent>
                <ProsAndCons review={review} />
                {erid && <Erid erid={erid} />}
                <TopicFooter enhancedTopic={enhancedTopic} />
              </>
            }
            {isGameComparePage && <GameCompare content={topic} />}
          </Grid>
        </Wrapper>
      </PageIndent>
    </div>
  );
};


function TopicPage({ content, isDesktop, location }) {
  const isGameComparePage = isGameCompare(location.pathname);
  const topicRenderer = (topic, enhancedTopic) => extendedTopicRenderer(
    topic,
    enhancedTopic,
    isGameComparePage,
    isDesktop,
  );

  if (isGameComparePage) {
    return (
      <Topic content={content}>
        {topicRenderer}
      </Topic>
    );
  }

  return (
    <InfiniteTopic
      content={content}
      rcmBlockId={resolve({
        '*': 'a9d612b637e94d029d28b7a5f704b014',
        'production': isDesktop ? '1b052876a31b48a69ad207f3da3f0b76' : 'b346e38832014fb8bddb8233629fa309',
      })}
    >
      {topicRenderer}
    </InfiniteTopic>
  );
}

TopicPage.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

export default withPageHocs(topicProvider)(withBreakpoint(TopicPage));
