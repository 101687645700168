import PropTypes from 'prop-types';

import Texts from 'core/components/Texts';

import { dateFormat } from 'core/utils/dates';
import pluralize from 'core/utils/pluralize';


export default function TopicMetaData(props) {
  const {
    commentsCount,
    publishedAt,
    timeOnly,
  } = props;

  const gotPublishDate = !!publishedAt;
  const publishDate = gotPublishDate && new Date(publishedAt);
  const dateIsValid = gotPublishDate && !!publishDate.valueOf();
  const date = dateIsValid && dateFormat(publishedAt, 'dd MMMM y, HH:mm');
  const time = dateIsValid && dateFormat(publishedAt, 'HH:mm');

  const commentsString = !timeOnly && commentsCount && `${commentsCount} ${pluralize(
    commentsCount,
    [
      'комментарий',
      'комментария',
      'комментариев',
    ]
  )}`;
  const result = [timeOnly ? time : date]
    .concat(timeOnly ? [] : [commentsString])
    .filter(Boolean)
    .join(', ');

  return <Texts type='cardsMeta'>{result}</Texts>;
}

TopicMetaData.propTypes = {
  /** Показывать только время, без даты. */
  timeOnly: PropTypes.bool,
  /** Количество комментариев. */
  commentsCount: PropTypes.number,
  /** Дата публикации в формате, поддерживаемом конструктором new Date(). */
  publishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** @ignore */
  theme: PropTypes.object,
};

TopicMetaData.displayName = 'TopicMetaData';

export { TopicMetaData as StorybookComponent };
