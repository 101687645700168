import PropTypes from 'prop-types';
import { incut as coreIncut } from 'core/components/TopicContent/blocks';

import Rug from 'site/components/Rug';

export default function Incut(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  return (
    <Rug>
      {coreIncut(block, parentProps, ctx)}
    </Rug>
  );
}

Incut.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
