import PropTypes from 'prop-types';

import { Mobile, Desktop } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import Feed from 'core/components/Feed';
import { Indent, NegativeMobile } from 'core/components/Wrappers';
import AdWrapper from 'core/components/Ad/AdWrapper';

import bindProps from 'core/components/bindProps';
import withViewportBreakpoint from 'core/components/breakpoint/withViewportBreakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import viewportBreakpointPropTypes from 'core/utils/prop-types/viewport-breakpoint';
import { DESKTOP_SMALL } from 'core/components/breakpoint/viewport-values';

import Rug from 'site/components/Rug';
import SeeAll from 'site/components/SeeAll';
import { SuperFooter, Native1Card3 } from 'site/components/Ads/desktop';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';
import MainBlockTitle from 'site/components/MainBlockTitle';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SMALL_INDENT,
  SIDE_INDENT_MOBILE,
  newsBaseUrl,
} from 'site/constants';

const Card1Type1 = bindProps({ type: 1 })(Card1);
const Card2Type4 = bindProps({ type: 4 })(Card2);
const Card3Type1 = bindProps({ type: 1 })(Card3);


function PerviyBlock(props) {
  const {
    topics: {
      mainTopics,
      newsTopics,
    },
    isMobile,
    isDesktop,
    viewportBreakpoint,
  } = props;

  if (!mainTopics?.length && !newsTopics?.length) return null;

  const isSmallViewport = viewportBreakpoint === DESKTOP_SMALL;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <>
      <NegativeMobile>
        <Indent top={isDesktop ? -20 : 0}>
          <Card1Type1
            content={mainTopics[0]}
            highPriorityImage
          />
        </Indent>
      </NegativeMobile>
      <Desktop>
        <Indent top={vertical} />
      </Desktop>
      <Section>
        <Block mobile={12}>
          <AdWrapper top={SMALL_INDENT}>
            <Rug
              top={isDesktop ? 0 : 100}
              right={isDesktop ? 0 : -SIDE_INDENT_MOBILE}
              left={isDesktop ? 100 : -SIDE_INDENT_MOBILE}
            >
              <Feed
                dataQa='news-main-list'
                title='Новости'
                headerComponent={MainBlockTitle}
                card={Card2Type4}
                content={newsTopics}
                interitemSpacing={isSmallViewport ? 10 : 20}
              />
            </Rug>
            <Indent top={30} />
            <SeeAll link={newsBaseUrl} title='Все новости' />
            <Mobile><ListingSpec /></Mobile>
          </AdWrapper>
        </Block>
        <Block desktop={`${SMALL_INDENT}px`} width={0} />
        <Block mobile={12} reorderMobile={-2} >
          <NegativeMobile>
            <Feed
              dataQa='news-main-cards'
              content={mainTopics.slice(1)}
              card={Card3Type1}
              interitemSpacing={SMALL_INDENT}
              cardsReplacement={{
                2: replaceCardWithAd(Native1Card3, isDesktop),
              }}
            />
          </NegativeMobile>
          <Mobile>
            <Indent top={vertical} bottom={vertical} >
              <Listing1 />
            </Indent>
          </Mobile>
        </Block>
      </Section>
      <Desktop>
        <AdWrapper top={vertical}>
          <SuperFooter />
        </AdWrapper>
      </Desktop>
    </>
  );
}

PerviyBlock.propTypes = {
  topics: PropTypes.shape({
    newsTopics: PropTypes.array,
    mainTopics: PropTypes.array,
  }),
  viewportBreakpoint: viewportBreakpointPropTypes(),
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default withViewportBreakpoint(PerviyBlock);
