import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';


export default function ListPageLayout({ children, showRecommender }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        {children}
        <Boroda showRecommender={showRecommender} />
      </ColumnLayout>
    </PageIndent>
  );
}

ListPageLayout.propTypes = {
  showRecommender: PropTypes.bool,
};
