import cx from 'classnames';

import ListPageLayoutSkeleton from 'site/components/ListPageLayout/Skeleton';

import styles from './index.styl';

export default function Skeleton() {
  return (
    <ListPageLayoutSkeleton>
      <div className={cx(styles.title, 'lb-skeleton')} />
      <div>
        <div className={cx(styles.topic, 'lb-skeleton')} />
        <div className={cx(styles.topic, 'lb-skeleton')} />
        <div className={cx(styles.topic, 'lb-skeleton')} />
        <div className={cx(styles.topic, 'lb-skeleton')} />
      </div>
    </ListPageLayoutSkeleton>
  );
}
