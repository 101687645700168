import PropTypes from 'prop-types';

import themeHOC from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import {
  DESKTOP,
  MOBILE,
} from 'core/components/breakpoint/values';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';


function MainBlockTitle(props) {
  const {
    marginBottom: marginBottomProp,
    marginLeft: marginLeftProp,
    children,
    breakpoint,
    theme,
    dataQa,
  } = props;

  if (!children) return null;

  const marginBottom = marginBottomProp !== undefined
    ? marginBottomProp
    : {
      [DESKTOP]: 20,
      [MOBILE]: 15,
    }[breakpoint];

  const marginLeft = marginLeftProp || 0;
  /**
   * TODO: добавить стили атому link.secondary и выпилить назначение
   * цветов в компонентах сайта для таких ссылок
   */
  return (
    <h2 className='mainBlockTitle' {...dataQa && { 'data-qa': dataQa }}>
      <style jsx>{`
        .mainBlockTitle
          font ${theme.texts.display1.font}
          color ${theme.texts.display1.color}
          margin 0 0 ${marginBottom}px ${marginLeft}px
          :global(a) &
            transition ${theme.animations.hover}
          :global(a:hover) &
            color ${theme.colors.active1}
      `}</style>
      {children}
    </h2>
  );
}

MainBlockTitle.propTypes = {
  /**
   * Этот проп нужен, т.к. у разных списочных компонентов LB разные принципы
   * добавления отступов под заголовки (например, Drum и Feed).
   */
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  dataQa: PropTypes.string,
};

const MainBlockTitleWithHOCs = withBreakpoint(themeHOC(MainBlockTitle));
MainBlockTitleWithHOCs.displayName = 'MainBlockTitle';

export default MainBlockTitleWithHOCs;

export { MainBlockTitle as StorybookComponent };
