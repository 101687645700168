export default {
  1: {
    headlineSize: '18px',
    imageWidth: 280,
    aspectRatio: 1.47,
    iconSize: 50,
    mobile: {
      videoIconCentered: true,
      headlineSize: '16px',
      aspectRatio: 1.6,
      iconSize: 50,
    },
  },
  2: {
    showAltHeadline: true,
    videoIconCentered: true,
    headlineSize: '24px',
    imageWidth: 880,
    aspectRatio: 1.8,
    iconSize: 100,
    mobile: {
      showAltHeadline: false,
      videoIconCentered: true,
      headlineSize: '18px',
      iconSize: 50,
    },
  },
};
