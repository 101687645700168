import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

import H1 from 'core/components/H1';
import { Indent } from 'core/components/Wrappers';
import AuthorsPage from 'core/components/AuthorsPage';

import ListPageLayout from 'site/components/ListPageLayout';

import {
  SMALL_INDENT,
} from 'site/constants';


function Authors({ rawAuthors }) {
  return (
    <ListPageLayout showRecommender>
      <H1>Авторы</H1>
      <Indent top={SMALL_INDENT} />
      <AuthorsPage rawAuthors={rawAuthors} />
    </ListPageLayout>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(Authors);
