import PropTypes from 'prop-types';
import coreMarkdown from 'core/components/TopicContent/blocks/markdown';

import styles from './index.styl';

export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const { interitemSpacing } = parentProps;

  return (
    <div className={styles.markdown}>
      <style jsx>{`
        .${styles.markdown}
          :global(ul)
          :global(ol)
            margin 0 auto ${interitemSpacing}px

            :global(ol)
            :global(ul)
              margin 20px auto
      `}</style>
      {coreMarkdown(block, parentProps, ctx)}
    </div>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
