import PropTypes from 'prop-types';

import themeHOC from 'core/components/theme';

import styles from './index.styl';

function ProsAndCons(props) {
  const {
    review,
    theme,
  } = props;

  if (!review || !(review.pros || review.cons)) return null;

  const {
    pros,
    cons,
  } = review;

  const prosList = pros ? pros.trim().split('\n').filter((Boolean)) : null;
  const consList = cons ? cons.trim().split('\n').filter((Boolean)) : null;

  return (
    <div className={styles.prosAndCons}>
      <style jsx>{`
        .${styles.prosAndCons}
          font-family ${theme.fonts.display}
          border-bottom 1px solid ${theme.colors.divider}
        .${styles.list}
          font ${theme.texts.body.font}
        .${styles.headline}
          font ${theme.texts.title2.font}
      `}
      </style>
      {
        [
          { title: 'Плюсы', list: prosList },
          { title: 'Минусы', list: consList },
        ].map((item, i) => {
          if (!item.list) return null;
          return (<div key={i} className={styles.listWrapper}>
            <div className={styles.headline} data-qa='pros-and-cons'>{item.title}</div>
            <ul className={styles.list}>
              {item.list.map((pro, j) => {
                return (<li className={styles.item} data-qa='pros-and-cons-item'
                  key={j}
                >{pro}</li>);
              })}
            </ul>
          </div>);
        })
      }
    </div>
  );
}

ProsAndCons.propTypes = {
  /** Данные из плагина "Оценка" топика. */
  review: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

const ProsAndConsWithHOCs = themeHOC(ProsAndCons);
ProsAndConsWithHOCs.displayName = 'ProsAndCons';

export default ProsAndConsWithHOCs;

export { ProsAndCons as StorybookComponent };
