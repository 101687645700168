import OpenSansBold from './OpenSans/OpenSans-Bold.woff';
import OpenSansItalic from './OpenSans/OpenSans-Italic.woff';
import OpenSansLight from './OpenSans/OpenSans-Light.woff';
import OpenSansLightItalic from './OpenSans/OpenSans-LightItalic.woff';
import OpenSansRegular from './OpenSans/OpenSans-Regular.woff';
import OpenSansSemiBold from './OpenSans/OpenSans-SemiBold.woff';

import OpenSansBold2 from './OpenSans/OpenSans-Bold.woff2';
import OpenSansItalic2 from './OpenSans/OpenSans-Italic.woff2';
import OpenSansLight2 from './OpenSans/OpenSans-Light.woff2';
import OpenSansLightItalic2 from './OpenSans/OpenSans-LightItalic.woff2';
import OpenSansRegular2 from './OpenSans/OpenSans-Regular.woff2';
import OpenSansSemiBold2 from './OpenSans/OpenSans-SemiBold.woff2';


const fonts = {
  text: 'OpenSans, Open Sans-fallback, Arial, sans-serif',
  get display() {
    return this.text;
  },
  get nuance() {
    return this.text;
  },
  faces: [
    {
      fontFamily: 'OpenSans',
      fontWeight: 700,
      src: `url(${OpenSansBold2}) format('woff2'), url(${OpenSansBold}) format('woff')`,
    },
    {
      fontFamily: 'OpenSans',
      fontWeight: 400,
      fontStyle: 'italic',
      src: `url(${OpenSansItalic2}) format('woff2'), url(${OpenSansItalic}) format('woff')`,
    },
    {
      fontFamily: 'OpenSans',
      fontWeight: 300,
      src: `url(${OpenSansLight2}) format('woff2'), url(${OpenSansLight}) format('woff')`,
    },
    {
      fontFamily: 'OpenSans',
      fontWeight: 300,
      fontStyle: 'italic',
      src: `url(${OpenSansLightItalic2}) format('woff2'), url(${OpenSansLightItalic}) format('woff')`,
    },
    {
      fontFamily: 'OpenSans',
      src: `url(${OpenSansRegular2}) format('woff2'), url(${OpenSansRegular}) format('woff')`,
    },
    {
      fontFamily: 'OpenSans',
      fontWeight: 600,
      src: `url(${OpenSansSemiBold2}) format('woff2'), url(${OpenSansSemiBold}) format('woff')`,
    },
    {
      fontFamily: 'Open Sans-fallback',
      sizeAdjust: '105.41%',
      ascentOverride: '105%',
      src: 'local("Arial")',
    },
  ],
};

export default fonts;
