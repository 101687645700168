import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';


export default function ListPageLayoutSkeleton({ children }) {
  return (
    <PageIndent>
      <ColumnLayout
        rightColumn={(
          <div className='skeleton' style={{ height: 32 }} />
        )}
      >
        {children}
      </ColumnLayout>
    </PageIndent>
  );
}
