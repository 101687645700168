import PropTypes from 'prop-types';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Feed from 'core/components/Feed';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import CardHorizontalDesktop, { CardHorizontalMobile } from 'site/cards/CardHorizontal';
import { Indent } from 'core/components/Wrappers';

import {
  SMALL_INDENT,
  VERTICAL_INDENT,
} from 'site/constants';

import adsList from './adsList';


const MIN_TOPICS_COUNT_TO_SHOW_AD = 10;
const MIN_TOPICS_COUNT_TO_SHOW_FIRST_AD = 4;

const spliceSize = index => index === 0 ? MIN_TOPICS_COUNT_TO_SHOW_FIRST_AD : MIN_TOPICS_COUNT_TO_SHOW_AD;

const renderSlice = (sliceProps) => {
  const {
    key,
    splicedTopics,
    adComponent: Ad,
    adProps,
    feedCard,
    indent,
  } = sliceProps;

  return (
    <div className='slice' key={key}>
      <style jsx>{`
        .slice
          padding-bottom ${indent}px
      `}</style>
      <Feed
        card={feedCard}
        content={splicedTopics}
        interitemSpacing={indent}
        dataQa='topic-list'
      />
      {Ad && splicedTopics.length >= (spliceSize(key)) && (
        <Ad {...adProps} />
      )}
    </div>
  );
};

const renderRemainingTopics = ({ topics, ...restProps }) => {
  const additionalAds = [];
  let count = 0;
  while (topics.length > 0) {
    const splicedTopics = topics.splice(0, MIN_TOPICS_COUNT_TO_SHOW_AD);
    additionalAds.push(renderSlice({ splicedTopics, key: `remaining-${count}`, ...restProps }));
    count++;
  }
  return additionalAds;
};


function TopicsList(props) {
  const {
    topics: dataTopics,
    breakpoint,
    isMobile,
  } = props;

  const topics = [...dataTopics];

  if (!topics.length) return null;

  const indent = isMobile ? 15 : SMALL_INDENT;

  const commonSliceProps = {
    feedCard: isMobile ? CardHorizontalMobile : CardHorizontalDesktop,
    indent,
  };

  const initialContent = adsList.map((params, i) => {
    const splicedTopics = topics.splice(0,  spliceSize(i));

    if (!splicedTopics.length) return null;

    const breakpointParams = params[breakpoint] || {};

    return renderSlice({
      key: i,
      splicedTopics,
      adComponent: breakpointParams.ad,
      adProps: breakpointParams.adProps,
      ...commonSliceProps,
    });
  });

  const lastParams = adsList[adsList.length - 1];
  const breakpointParams = lastParams[breakpoint] || {};
  const remainingContent = renderRemainingTopics({
    topics,
    adComponent: breakpointParams.ad,
    adProps: breakpointParams.adProps,
    ...commonSliceProps,
  });

  return (
    <AdWrapper top={indent}>
      {initialContent}
      {remainingContent}
      <Indent top={VERTICAL_INDENT} />
    </AdWrapper>
  );
}

TopicsList.propTypes = {
  /** Массив топиков */
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  isMobile: PropTypes.bool,
};

const TopicsListWithHOCs = withBreakpoint(TopicsList);
TopicsListWithHOCs.displayName = 'TopicsList';

export default TopicsListWithHOCs;

export { TopicsList as StorybookComponent };
