import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withTheme from 'core/components/theme';

import './global.styl';

const GlobalNativeStyles = props => {
  const { theme } = props;

  return (
    <Fragment>
      <style global jsx>{`
        .native-ad
          .ad_native_img
            background-color ${theme.colors.placeholder}
            &:after
              transition opacity ${theme.animations.hover}
            &:before
              padding-top ${100 / 1.47 + '%'}

          .ad_label__text
            color ${theme.colors.hint}

          .ad_native_title
            transition color ${theme.animations.hover}

          a
            color ${theme.colors.primary}
            font-family ${theme.fonts.text}
            &:hover
              .ad_native_title
                color ${theme.colors.active1}
              .ad_native_img:after
                background-color ${theme.colors.primary}
                opacity 0.3
      `}</style>
    </Fragment>
  );
};

GlobalNativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(GlobalNativeStyles);
