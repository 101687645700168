import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import TagTopics from 'core/components/TagTopics';

import withPageHocs from 'core/components/withPageHocs';

import { getPage } from 'core/utils/url-helper';

import ListPageLayout from 'site/components/ListPageLayout';
import TopicsList from 'site/components/TopicsList';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import Skeleton from './Skeleton';


function TagPage({ tag, topics: resolvedTopics, location }) {
  return (
    <ListPageLayout showRecommender>
      <TagTopics
        tag={tag}
        topics={resolvedTopics}
        limit={RUBRICS_PAGE_LIMIT}
      >
        {({ topics, title, descriptionMeta }) => {
          const page = getPage(location.search);
          const description = `${descriptionMeta || title} - все материалы по теме и новости технологий — Ferra.ru`;
          const descriptionSuffix = page > 1 ? ` - страница ${page}` : '';

          return (
            <>
              <Helmet>
                <meta
                  name='description'
                  content={description + descriptionSuffix}
                />
              </Helmet>
              <TopicsList topics={topics} />
            </>
          );
        }}
      </TagTopics>
    </ListPageLayout>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
  location: PropTypes.object,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: 'image,rubric',
  }),
});

export default withPageHocs(dataProvider, Skeleton)(TagPage);
