import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import themeHOC from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

/**
 * TODO:
 *  Выпилить компонент и переписать заголовки H2 на сайте
 * с помощью core/components/H2, стили применять по месту.
 */

function Header(props) {
  const {
    type,
    is,
    children,
    theme: {
      texts: textsAtoms,
    },
  } = props;

  const textsAtomsType = textsAtoms[type];

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          font ${textsAtomsType.font}
          color ${textsAtomsType.color}
          margin ${textsAtomsType.margin}
          letter-spacing ${textsAtomsType.letterSpacing}
      `}</style>
    </scope>
  );

  const className = scoped.wrapClassNames('headline');

  return (
    <Fragment>
      {React.createElement(is || 'div', { className }, children)}
      <scoped.styles />
    </Fragment>
  );
}

const textContract = `{
  font,
  letterSpacing,
  color,
}`;

Header.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'display3']).isRequired,
  /** Имя тега */
  is: PropTypes.string,
  theme: themePropTypes(`{
    texts: {
      h1: ${textContract},
      h2: ${textContract},
      display3: ${textContract},
    }
  }`),
};

export default themeHOC(Header);
