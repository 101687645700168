export default {
  1: {
    contentPadding: '20px',
    headlineSize: '18px',
    imageWidth: 430,
    aspectRatio: 1.47,
    smallFont: '14px',
    mobile: {
      contentPadding: '20px',
      headlineSize: '18px',
      aspectRatio: 1.6,
    },
  },
  2: {
    showVideoIcon: true,
    showAltHeadline: true,
    contentPadding: '40px 20px',
    headlineSize: '24px',
    imageWidth: 880,
    aspectRatio: 1.83,
  },
  3: {
    contentPadding: '20px',
    headlineSize: '18px',
    imageWidth: 280,
    aspectRatio: 1.07,
    smallFont: '12px',
    mobile: {
      imageWidth: 260,
      showAltHeadline: true,
      contentPadding: '20px',
      headlineSize: '18px',
      aspectRatio: 1,
    },
  },
};
