import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Author from 'site/components/Author';

import styles from './index.styl';


export default function Opinion(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    theme: {
      colors: colorsAtoms,
      texts: {
        opinion: opinionAtoms,
      },
    },
  } = parentProps;

  if (!block) return null;

  const {
    author,
    body,
  } = block.attributes;

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.wrapper}
          background ${colorsAtoms.quote}
          border-left 2px solid ${colorsAtoms.active1}

        .${styles.quote}
          font ${opinionAtoms.font}
      `}
      </style>
      <div className={styles.quote}>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </div>
      <Author content={author} type={4} />
    </div>
  );
}

Opinion.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
};
