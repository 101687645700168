import PropTypes from 'prop-types';

import Calendar from 'site/icons/Calendar';

import { STARTING_YEAR } from '../constants';

import styles from './index.styl';


export default function MobileDatepicker({
  value,
  onChange,
}) {
  return (
    <div className={styles.wrapper}>
      <Calendar className={styles.icon} />
      <input
        className={styles.input}
        type='date'
        value={value}
        min={`${STARTING_YEAR}-01-01`}
        onChange={event => {
          const { target: { value: newValue } } = event;
          if (!newValue) return;
          onChange(newValue);
        }}
      />
    </div>
  );
}

MobileDatepicker.propTypes = {
  /** Объект даты */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string, // пустая строка
  ]).isRequired,
  /** Коллбек изменения даты */
  onChange: PropTypes.func.isRequired,
};
