import PropTypes from 'prop-types';

import ReviewsDesktop from './Desktop';
import ReviewsMobile from './Mobile';


function Reviews({ content, isDesktop }) {
  if (!content?.length) return null;

  const ReviewsComponent = isDesktop ? ReviewsDesktop : ReviewsMobile;

  return <ReviewsComponent content={content} />;
}

Reviews.propTypes = {
  content: PropTypes.array,
  /** @ignore */
  isDesktop: PropTypes.bool,
};

export default Reviews;
