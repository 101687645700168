import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import Page from 'core/components/Page';
import { PageIndent, Indent, TopicContentMaxWidth } from 'core/components/Wrappers';
import ColumnLayout from 'core/components/ColumnLayout';

import withPageHocs from 'core/components/withPageHocs';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import resolveRelationships from 'core/utils/relationships';

import TopicHeader from 'site/pages/topic/Header';
import InnerSideColumn from 'site/components/InnerSideColumn';

const relationships = resolveRelationships(['content'], {});


function LeaderBoard({ content, isMobile, theme }) {
  return (
    <Page>
      <style jsx>{`
        .announce
          font 400 22px/33px ${theme.fonts.text}
          color ${theme.colors.primary}
          :global(.mobile) &
            font 16px/22px ${theme.fonts.text}
      `}</style>
      <PageIndent>
        <Topic content={content}>
          {topic => {
            const {
              content: {
                widgets,
              },
            } = relationships(topic);

            if (widgets.length === 0) return null;

            const {
              final_announce: finalAnnounce,
            } = widgets[0].attributes;

            return (
              <ColumnLayout rightColumn={<InnerSideColumn />}>
                <Indent top={20} bottom={isMobile ? 80 : 50} >
                  <TopicHeader content={topic} isGameCompare />
                  <TopicContentMaxWidth>
                    <div className='announce'>{finalAnnounce}</div>
                    <Indent top={isMobile ? 35 : 25} />
                  </TopicContentMaxWidth>
                  <LeaderBoardCore content={topic} widget={widgets[0]} />
                </Indent>
              </ColumnLayout>
            );
          }}
        </Topic>
      </PageIndent>
    </Page>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  content: PropTypes.object,
};

export default withPageHocs(topicProvider)(withBreakpoint(withTheme(LeaderBoard)));
