const palette = {
  black: '#000',
  white: '#fff',

  gray100: '#f5f5f5',
  gray300: '#e0e0e0',
  gray600: '#999',
  gray700: '#616161',
};

export default palette;
