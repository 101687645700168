import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import bindProps from 'core/components/bindProps';

import { Indent } from 'core/components/Wrappers';
import { SMALL_INDENT } from 'site/constants';
import SeeAll from 'site/components/SeeAll';

import MainBlockTitle from 'site/components/MainBlockTitle';

import CardHorizontalDesktop, { CardHorizontalMobile } from 'site/cards/CardHorizontal';


const DesktopCard = bindProps({ showAuthor: true })(CardHorizontalDesktop);
const MobileCard = bindProps({ showAuthor: true })(CardHorizontalMobile);

function Experts({ content, isDesktop }) {
  if (!content?.length) return null;

  return (
    <div>
      <Feed
        dataQa='experts'
        title='Экспертное мнение'
        content={content}
        headerComponent={MainBlockTitle}
        card={isDesktop ? DesktopCard : MobileCard}
        interitemSpacing={isDesktop ? 20 : 15}
        columns={1}
      />
      <Indent top={SMALL_INDENT} />
      <SeeAll link={'/experts'} title={'Все мнения'} />
    </div>
  );
}

Experts.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Experts);
