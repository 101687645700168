import cx from 'classnames';
import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import Image from 'core/components/SmartImage';
import ThemeSwitch from 'core/components/ThemeSwitch';

import resolveRelationships from 'core/utils/relationships';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import bindProps from 'core/components/bindProps';

import Card2 from 'site/cards/Card2';

import SeeAll from 'site/components/SeeAll';
import MainBlockTitle from 'site/components/MainBlockTitle';
import { Indent } from 'core/components/Wrappers';

import {
  SMALL_INDENT,
  MEDIUM_SIDE_INDENT,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'tags',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Selection(props) {
  const {
    content: {
      selection,
      topics,
    },
    theme,
    isMobile,
    breakpoint,
  } = props;

  if (!selection || !topics?.length) return null;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    tags,
  } = relationships(selection);

  const mainTag = tags[0];
  const paddingVertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const paddingHorizontal = isMobile ? SIDE_INDENT_MOBILE : 32;

  const imageProps = isMobile
    ? {
      height: 800,
    }
    : {
      width: 880,
      height: 500,
    };

  const FeedCard = bindProps({
    type: 3,
    utm: `?utm_source=read_more&utm_medium=${breakpoint}&utm_campaign=Research_read_more_traffic`,
  })(Card2);

  return (
    <div className={cx('selection', styles.selection)}>
      <style jsx>{`
        .selection
          padding ${paddingVertical}px ${paddingHorizontal}px
          margin-bottom ${paddingVertical}px
        .hr
          border 1px solid ${theme.colors.content}
      `}</style>

      <div className={styles.imageContainer}>
        <Image
          src={cover}
          previewSrc={previewCover}
          {...imageProps}
        />
      </div>
      <div className={styles.content}>
        <ThemeSwitch name='dark'>
          <MainBlockTitle>Главная тема</MainBlockTitle>
          {mainTag &&
            <SeeAll
              tough
              link={'/label/' + mainTag.attributes.slug}
              title={selection.attributes.headline}
            />
          }
          <Indent top={SMALL_INDENT} />
          <hr className={cx('hr', styles.hr)} />
          <Indent top={SMALL_INDENT} />
          <Feed
            card={FeedCard}
            content={topics}
            interitemSpacing={isMobile ? SMALL_INDENT : MEDIUM_SIDE_INDENT}
            columns={3}
            grid
          />
        </ThemeSwitch>
      </div>
    </div>
  );
}

Selection.propTypes = {
  content: PropTypes.shape({
    selection: modelPropTypes(topicAttributes),
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  }),
  isMobile: PropTypes.bool.isRequired,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object.isRequired,
};

export default Selection;
