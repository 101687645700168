import { compose, nest } from 'core/libs/recompose';

import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import { NegativeMobile } from 'core/components/Wrappers';
import listingSpecStyles from 'site/components/Ads/styles/listing-spec';
import cardReadMoreStyles from 'site/components/Ads/styles/card-read-more';


/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const MobileAd = bindPropsHOC({
  siteId: '474959934',
  height: 250,
})(Ad);

export const TopBanner = bindPropsHOC({
  name: 'TopBanner',
  blockId: '556396229',
  options: {},
})(MobileAd);

export const Fullscreen = bindPropsHOC({
  name: 'Fullscreen',
  height: null,
  blockId: '556396119',
  options: {},
})(MobileAd);

export const Rich = bindPropsHOC({
  name: 'Rich',
  height: null,
  blockId: '579503980',
  options: {},
})(MobileAd);

export const Listing1 = bindPropsHOC({
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '556396177',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Listing2 = bindPropsHOC({
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '556396189',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Listing3 = bindPropsHOC({
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '556396199',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Listing4 = bindPropsHOC({
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '556396201',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const ListingSpec = compose(
  bindPropsHOC({
    name: 'ListingSpec (COM)',
    lazy: true,
    blockId: '556396157',
    options: {},
  }),
  listingSpecStyles,
)(MobileAd);

export const Footer = bindPropsHOC({
  name: 'Footer (COM)',
  lazy: true,
  blockId: '556396115',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Content1 = bindPropsHOC({
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '556396051',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Content2 = bindPropsHOC({
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '556396061',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Content3 = bindPropsHOC({
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '556396083',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Content4 = bindPropsHOC({
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '556396095',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Content5 = bindPropsHOC({
  name: 'Content5 (COM)',
  lazy: true,
  blockId: '556396109',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const ContentSpec = compose(
  bindPropsHOC({
    name: 'ContentSpec',
    height: 195,
    lazy: true,
    blockId: '579361996',
    options: {},
    type: 'mobile',
    siteId: '474959934',
  }),
  cardReadMoreStyles,
)(Ad);

export const InPage = bindPropsHOC({
  name: 'InPage',
  height: 200,
  // С domId не забываем контролировать, чтобы InPage был всего один на страницу
  domId: 'in-read-banner',
  blockId: '556396129',
  options: {},
})(nest(NegativeMobile, MobileAd));

export const Sponsored = compose(
  bindPropsHOC({
    name: 'Sponsored (COM)',
    height: null,
    blockId: '556396217',
    options: {},
  }),
  sponsoredStyles
)(MobileAd);

export const InGallery = bindPropsHOC({
  name: 'InGallery',
  blockId: '579389984',
  disableAutoInit: true,
  height: null,
})(MobileAd);

export const PopUp = bindPropsHOC({
  name: 'PopUp',
  blockId: '579502893',
  options: {
    siteId: '474959934',
  },
})(Ad);
