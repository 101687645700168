import PropTypes from 'prop-types';
import withTheme from 'core/components/theme';

import styles from './index.styl';

function Rug({
  children,
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  theme,
}) {
  const {
    active1,
    content,
  } = theme.colors;

  return (
    <div className={styles.rug}>
      <style jsx>{`
        .${styles.content}:after
          top ${top}px
          bottom ${bottom}px
          left ${left}px
          right ${right}px
          transition opacity ${theme.animations.hover}
          background repeating-linear-gradient(
            -45deg,
            ${active1},
            ${active1} 1px,
            ${content} 1px,
            ${content} 8px
          )
      `}</style>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

Rug.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  theme: PropTypes.object,
};

Rug.defaultProps = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};


const RugWithHOCs = withTheme(Rug);
RugWithHOCs.displayName = 'Rug';

export default RugWithHOCs;

export { Rug as StorybookComponent };
