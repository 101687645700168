import PropTypes from 'prop-types';

import { SMALL_INDENT } from 'site/constants';


export default function ReviewsTabSkeleton({ failed }) {
  return (
    <div
      style={{
        display: 'grid',
        position: 'relative',
        gap: SMALL_INDENT,
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    >
      {new Array(6).fill(null).map((v, i) => (
        <div
          key={i}
          style={{ aspectRatio: '1.07', opacity: failed ? 0.5 : 1 }}
          className='skeleton'
        />
      ))}
      {failed &&
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
        >
          ⚠️ Что-то сломалось,<br />попробуйте открыть вкладку снова
        </div>
      }
    </div>
  );
}

ReviewsTabSkeleton.propTypes = {
  failed: PropTypes.bool,
};
