import PropTypes from 'prop-types';

import { NegativeMobile } from 'core/components/Wrappers';

import TopicHeaderImage from './HeaderImage';
import TopicHeaderGallery from './HeaderGallery';
import TopicHeaderVideo from './HeaderVideo';


const topicTypeMap = {
  news: TopicHeaderImage,
  article: TopicHeaderImage,
  gallery: TopicHeaderGallery,
  video: TopicHeaderVideo,
};

function TopicHeaderMedia({ content }) {
  const topicType = content.attributes.topic_type;
  const HeaderContent = topicTypeMap[topicType] || TopicHeaderImage;

  return (
    <NegativeMobile>
      <HeaderContent content={content} />
    </NegativeMobile>
  );
}

TopicHeaderMedia.propTypes = {
  content: PropTypes.object.isRequired,
};

export default TopicHeaderMedia;
