import { get } from 'core/libs/lodash';

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Формирует ссылку на рубрику
 */
export function buildRubricUrl(slug, rootSlug) {
  if ((slug === rootSlug) || !rootSlug) {
    return `/${slug}`;
  }
  return `/${rootSlug}/${slug}`;
}

export function imageVersionExists(cover) {
  if (cover && cover.width && cover.height && cover.rel_url) return true;
  return false;
}

export function doubleDashesReplacer(str, replace) {
  return str.replace(/-{2}/g, replace || '');
}

/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */

export function getAvatar(person = {}, version = 'original') {
  const path = [
    'attributes',
    'avatar',
    'attributes',
    'versions',
    version,
  ];
  const defaultAvatar = {
    rel_url: require('site/images/user-avatar.png'),
    width: 80,
    height: 80,
  };

  return get(person, path, defaultAvatar);
}
