import PropTypes from 'prop-types';
import GalleryWithAds from 'site/components/GalleryWithAds';


function TopicHeaderGallery({ content }) {
  const {
    photo_gallery: {
      data: photoGallery,
    },
  } = content.relationships;

  return photoGallery ? (
    <GalleryWithAds
      photoGallery={photoGallery}
      showDownloadIcon
      hashNavigation
      adsToReload={[
        {
          name: 'Billboard',
          count: 4,
        },
        {
          name: '240x400',
          count: 4,
        },
        {
          name: 'TopBanner',
          count: 4,
        },
      ]}
    />
  ) : null;
}

TopicHeaderGallery.propTypes = {
  content: PropTypes.object,
};

export default TopicHeaderGallery;
