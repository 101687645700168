import PropTypes from 'prop-types';
import cx from 'classnames';

import themeHOC from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Link from 'core/components/Link';

import pluralize from 'core/utils/pluralize';

import ButtonWithIcon from 'core/components/ButtonWithIcon';
import Socializator from 'core/components/Socializator';
import CommentsCount from 'core/components/CommentsCount';

import Comments from 'site/icons/Comments';

import {
  SMALL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';


function SocialShare(props) {
  const {
    xid,
    commentsCount,
    theme,
    isMobile,
    centered,
    columnLayout,
    topicLink,
    isCommentsDisabled,
  } = props;

  const counter = (
    <CommentsCount
      xid={xid}
      disableIcon
    />
  );

  const commentsText = commentsCount
    ? commentsCount + ' ' + pluralize(commentsCount, ['комментарий', 'комментария', 'комментариев'])
    : 'Обсудить';

  const asColumn = isMobile || columnLayout;
  const indent = isMobile ? VERTICAL_INDENT_MOBILE : SMALL_INDENT;

  return (
    <div className={cx(
      'wrapper',
      styles.wrapper,
      centered && styles.centered,
      isMobile && styles.mobile
    )}
    >
      <style jsx>{`
        .wrapper
          flex-direction ${asColumn ? 'column' : 'row'}

        .comments
          margin-bottom ${asColumn ? indent + 'px' : 0}
          :global [id*=$]
            fill ${theme.colors.primary}

        .social
          margin-left ${asColumn ? 'initial' : 'auto'}
      `}</style>

      {!isCommentsDisabled && (
        <div className='comments'>
          <Link type='secondary' to={`${topicLink}/comments`}>
            <ButtonWithIcon
              icon={Comments}
              size='small'
            >
              {commentsText}
              <span className={styles.counter}>
                {counter}
              </span>
            </ButtonWithIcon>
          </Link>
        </div>
      )}
      <div className={cx('social', styles.social)}>
        <Socializator />
      </div>
    </div>
  );
}

SocialShare.propTypes = {
  xid: PropTypes.string.isRequired,
  /**
   * Кол-во комментариев, которое будет отображаться
   * на соответствующей кнопке.
   */
  commentsCount: PropTypes.number,

  /** Показывать кнопки соц. сетей под кнопкой комментариев. */
  columnLayout: PropTypes.bool,

  /** Центрировать содержимое */
  centered: PropTypes.bool,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
  topicLink: PropTypes.string,

  /** Скрывать комментарии */
  isCommentsDisabled: PropTypes.bool,
};

const SocialShareWithHOCs = withBreakpoint(themeHOC(SocialShare));
SocialShareWithHOCs.displayName = 'SocialShare';

export default SocialShareWithHOCs;

export { SocialShare as StorybookComponent };
