import PropTypes from 'prop-types';
import { Fragment } from 'react';
import cx from 'classnames';

import themeHOC from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withViewportBreakpoint from 'core/components/breakpoint/withViewportBreakpoint';
import viewportBreakpointPropTypes from 'core/utils/prop-types/viewport-breakpoint';
import { DESKTOP_SMALL } from 'core/components/breakpoint/viewport-values';
import styles from './index.styl';


function Mark(props) {
  const {
    review,
    isMobile,
    theme,
    compact,
    viewportBreakpoint,
  } = props;

  if (!review || !review.rating) return null;

  const {
    rating,
    device_name: deviceName,
  } = review;

  const fixedRating = parseFloat(rating);
  const isSmallViewport = viewportBreakpoint === DESKTOP_SMALL;

  return (
    <div
      className={cx(
        styles.markContainer,
        'markContainer',
        isMobile && styles.mobile,
        compact && styles.compact,
        isSmallViewport && styles.smallWrapper
      )}
    >
      <style jsx>{`
        .markContainer
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
        .mark
          background ${theme.colors.active1}
        .markTitle
          font ${theme.texts.body.font}
        .deviceName
          font ${theme.texts.title2.font}
        .bottomLine
          background-color ${theme.colors.divider}
      `}</style>

      <div className={cx(styles.mark, 'mark')}>
        <div className={styles.rating}>
          {fixedRating}
        </div>
        <div className={styles.total}>
          /10
        </div>
      </div>

      {!compact &&
        <Fragment>
          <div className={styles.info}>
            <div
              className={cx(
                'markTitle',
                styles.markTitle,
                isMobile && styles.markTitleMobile
              )}
            >
              Оценка по версии Ferra
            </div>
            <div className={cx(styles.deviceName, 'deviceName')}>
              {deviceName}
            </div>
          </div>
          <div className={cx('bottomLine', styles.bottomLine)} />
        </Fragment>
      }
    </div>
  );
}

Mark.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Показывать только оценку и уменьшить размер. */
  compact: PropTypes.bool,
  /** Данные из плагина "Оценка" топика. */
  review: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  viewportBreakpoint: viewportBreakpointPropTypes(),
};

const MarkWithHOCs = themeHOC(withBreakpoint(withViewportBreakpoint(Mark)));
MarkWithHOCs.displayName = 'Mark';

export default MarkWithHOCs;

export { Mark as StorybookComponent };
