import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import resolve from 'core/resolver/resolve';
import { rawRubricFetcher, rawTopicsFetcher } from 'core/fetchers/rubric';

import Rubric from 'core/components/Rubric';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { getPage } from 'core/utils/url-helper';
import { isGameCompare } from 'core/components/GameCompare/utils';

import ListPageLayout from 'site/components/ListPageLayout';
import TopicsList from 'site/components/TopicsList';

import Skeleton from './Skeleton';
import GameCompareRubric from './GameCompareRubric';
import TitleComponent from './TitleComponent';

import {
  RUBRICS_PAGE_LIMIT,
} from 'site/constants';


function RubricPage({ rawRubric, rawTopics, location }) {
  return (
    <ListPageLayout showRecommender>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        limit={RUBRICS_PAGE_LIMIT}
        titleComponent={TitleComponent}
        typeOfSubrubrics='select'
        showSubrubrics
      >
        {({ topics, titleMeta, descriptionMeta }) => {
          const {
            data: {
              attributes: {
                title: rubricTitle,
                root_title: rootTitle,
              },
            },
          } = rawRubric;


          const isGameComparePage = isGameCompare(location.pathname);
          const page = getPage(location.search);
          const rubricsStr = rubricTitle !== rootTitle
            ? `${rubricTitle} - ${rootTitle}`
            : rubricTitle;

          const pageTitle = titleMeta || `${rubricsStr} — все статьи и новости`;
          let pageDescription = descriptionMeta || `Все статьи и новости по теме: ${rubricsStr} — Ferra.ru`;
          pageDescription = page > 1 ? `${pageDescription} - страница ${page}` : pageDescription;

          return (
            <Fragment>
              <Helmet>
                <title>{pageTitle}</title>
                <meta name='description' content={pageDescription} />
              </Helmet>
              {!isGameComparePage && <TopicsList topics={topics} />}
              {isGameComparePage && <GameCompareRubric topics={topics} />}
            </Fragment>
          );
        }}
      </Rubric>
    </ListPageLayout>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit: RUBRICS_PAGE_LIMIT,
    include: 'image,rubric,content',
  }),
});

export default withPageHocs(dataProvider, Skeleton)(
  withBreakpoint(RubricPage)
);
