import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import Topic from 'core/components/Topic';
import { PageIndent, Indent, TopicContentMaxWidth } from 'core/components/Wrappers';
import ColumnLayout from 'core/components/ColumnLayout';
import EmptyWrapper from 'core/components/EmptyWrapper';

import withPageHocs from 'core/components/withPageHocs';

import InnerSideColumn from 'site/components/InnerSideColumn';

import TopicHeader from 'site/pages/topic/Header';


function CommentsPage(props, context) {
  return (
    <PageIndent>
      <Topic content={props.content}>
        {(topic) => {
          const {
            id,
            attributes: {
              link,
              is_premium: isPremium,
              is_comments_disabled: isCommentsDisabled,
            },
          } = topic;

          const commentsProps = {
            xid: id,
            adv: true,
            advInterval: 4,
            pageUrl: `https://${context.host}${link}/comments`,
            isCommentsDisabled,
          };

          const Wrapper = isPremium ? EmptyWrapper : ColumnLayout;

          return (
            <Wrapper rightColumn={<InnerSideColumn />}>
              {!isPremium && (
                <Indent bottom={20}>
                  <TopicHeader content={topic} isCommentsPage />
                </Indent>
              )}

              <TopicContentMaxWidth>
                <Link
                  to={link}
                  type='secondary'
                >
                  <Button size='small'>Вернуться к статье</Button>
                </Link>
                <Comments {...commentsProps} />
              </TopicContentMaxWidth>
            </Wrapper>
          );
        }}
      </Topic>
    </PageIndent>
  );
}

CommentsPage.propTypes = {
  content: PropTypes.object,
};

CommentsPage.contextTypes = {
  host: PropTypes.string,
};

export default withPageHocs(topicProvider)(CommentsPage);
