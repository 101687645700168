import GlobalStyles from '../global';
import './index.styl';

export default function ListingSpec(Component) {
  return props => (
    <div className='native-ad listing-spec'>
      <GlobalStyles />
      <Component {...props} />
    </div>
  );
}
