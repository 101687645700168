import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import PopularCore from 'core/components/Popular';

import Rug from 'site/components/Rug';
import Header from 'site/components/Header';
import Card2 from 'site/cards/Card2';

const include = filterRequiredParams([Card2], 'include');
const fields = filterRequiredParams([Card2], 'fields');

function MostPopular({ headerStyleAtom }) {
  return (
    <Rug top={20}>
      <Header type={headerStyleAtom}>Самое читаемое</Header>
      <div style={{ padding: 20 }}>
        <PopularCore
          card={Card2}
          interitemSpacing={24}
          limit={6}
          sort='-views_count'
          include={include}
          fields={fields}
        />
      </div>
    </Rug>
  );
}

MostPopular.propTypes = {
  headerStyleAtom: PropTypes.string,
};

MostPopular.defaultProps = {
  headerStyleAtom: 'display3',
};

export default MostPopular;
