import cx from 'classnames';

import ListPageLayoutSkeleton from 'site/components/ListPageLayout/Skeleton';

import styles from './index.styl';


export default function Skeleton() {
  return (
    <ListPageLayoutSkeleton>
      <div className={cx(styles.title, 'lb-skeleton')} />
      <div className={cx(styles.tags, 'lb-skeleton')} />
      <div className={styles.topics}>
        <div className={cx(styles.mainTopic, 'lb-skeleton')} />
        <div className={styles.basicTopics}>
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
        </div>
      </div>
    </ListPageLayoutSkeleton>
  );
}
