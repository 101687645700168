import PropTypes from 'prop-types';
import { Block, Section } from 'core/components/Grid';

import Rug from 'site/components/Rug';
import { Indent, NegativeMobile } from 'core/components/Wrappers';

import Card6 from 'site/cards/Card6';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import MainBlockTitle from 'site/components/MainBlockTitle';

const MobileTitle = ({ children }) => (
  <Indent left={SIDE_INDENT_MOBILE} right={SIDE_INDENT_MOBILE}>
    <MainBlockTitle>{children}</MainBlockTitle>
  </Indent>);


function Numbers(props) {
  const {
    numberOfTheDay,
    historyNumber,
    isMobile,
  } = props;

  if (!numberOfTheDay && !historyNumber) return null;

  const Wrapper = isMobile
    ? NegativeMobile
    : Section;
  const TitleComponent = isMobile
    ? MobileTitle
    : MainBlockTitle;
  const rugProps = isMobile
    ? { top: 13, left: -15, right: -15 }
    : { top: 22 };

  return (
    <Rug {...rugProps}>
      <Wrapper>
        {!!numberOfTheDay &&
          <Block>
            <TitleComponent>Цифра дня</TitleComponent>
            <Card6
              dataQa='numbers'
              content={numberOfTheDay}
            />
          </Block>
        }

        <Block desktop={SIDE_INDENT + 'px'} mobile={0} />

        {!!historyNumber &&
          <Block>
            <TitleComponent>День в истории</TitleComponent>
            <Card6
              dataQa='days'
              content={historyNumber}
            />
          </Block>
        }
      </Wrapper>
    </Rug>
  );
}

Numbers.propTypes = {
  numberOfTheDay: PropTypes.object,
  historyNumber: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default Numbers;
