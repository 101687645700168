import PropTypes from 'prop-types';

import Link from 'core/components/Link';

import relationshipsPropTypes from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';

import DailyNumber from 'site/icons/DailyNumber';

import styles from './index.styl';

const requiredPayloadImports = ['number'];

const requiredPayloadFields = [
  'link',
];

const relationships = resolveRelationships(requiredPayloadImports, {});

const CHARS_PER_LINE = 4;
const DEFAULT_FONT_SIZE = 150;

function Card6(props) {
  const {
    content,
    dataQa,
    theme,
  } = props;

  const {
    link,
  } = content.attributes;

  const {
    number: {
      body,
      unit,
      value,
    },
  } = relationships(content);

  if (!value) {
    return null;
  }

  let currentFontSize = DEFAULT_FONT_SIZE;
  const relativeSize = CHARS_PER_LINE / (value.length + unit.length);

  if (relativeSize < 1) {
    currentFontSize = relativeSize * currentFontSize;
  }

  return (
    <Link
      type='secondary'
      to={link}
      {...!!dataQa && { dataQa }}
    >
      <style jsx>{`
        .${styles.unit}
          font-family ${theme.fonts.display}
          transition opacity ${theme.animations.hover}
          color ${theme.colors.active1}
          :global([id*="$"])
            fill ${theme.colors.active1}

          :global(svg)
            font-size ${currentFontSize}px

        .body
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}
          transition color ${theme.animations.hover}

        .${styles.card}
          &:hover .body
            color ${theme.colors.active1}
      `}
      </style>
      <div className={styles.card}>
        <div className={styles.unit}><DailyNumber value={`${value}${unit || ''}`} /></div>
        <div className={styles.texts}>
          {body && <div className='body'>{body}</div>}
        </div>
      </div>
    </Link>
  );
}

Card6.propTypes = {
  /** @ignore */
  content: relationshipsPropTypes({
    number: PropTypes.object,
  }),

  dataQa: PropTypes.string,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};


const Card = cardHOC(Card6);

Card.displayName = 'Card6';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card6 as StorybookComponent };
