export default {
  1: {
    showDate: true,
  },
  2: {
    showPosition: true,
  },
  3: {
    showDate: true,
    whiteDate: true,
  },
  4: {
    showDate: true,
    smallFont: true,
  },
};
