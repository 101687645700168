import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Image from 'core/components/SmartImage';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function CardReadMore2({ content, theme }) {
  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const {
    animations: {
      hover: hoverAnimation,
    },
    fonts: fontsAtoms,
    colors: colorsAtoms,
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          color ${colorsAtoms.primary}
          transition color ${hoverAnimation}

          &:hover
            color ${colorsAtoms.active1}
      `}</style>
    </scope>
  );

  return (
    <Link
      type='secondary'
      to={link}
      className={scope.wrapClassNames(styles.link)}
    >
      <style jsx>{`
        .${styles.headline}
          font-family ${fontsAtoms.display}
        .${styles.overlay}
          transition opacity ${hoverAnimation}
      `}</style>
      {cover && (
        <div className={styles.image}>
          <Image
            src={cover}
            width={266}
            height={162}
            aspectRatio={1}
          />
          <div className={styles.overlay} />
        </div>
      )}
      <div className={styles.headline}>{listHeadline || headline}</div>
      <scope.styles />
    </Link>
  );
}

CardReadMore2.propTypes = {
  /** @ignore */
  content: PropTypes.object,

  /** @ignore */
  theme: PropTypes.object,
};

const Card = cardHOC(CardReadMore2);

Card.displayName = 'CardReadMore2';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardReadMore2 as StorybookComponent };
